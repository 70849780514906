import React from 'react';
import axios from 'axios';
import { useQueryClient } from '@tanstack/react-query';
import { ThemeContext } from 'themes';
import { translatePathWithParam } from 'core';
import { useRouter } from 'next/router';
import routes from 'routes';
import { UserContext } from 'lib/user';
import { siteConfig } from 'lib/site';

const { API_URL } = process.env;

function useLogout() {
    const router = useRouter();
    const queryClient = useQueryClient();
    const { setCustomTheme } = React.useContext(ThemeContext);
    const { setUserInfos } = React.useContext(UserContext);

    const logout = async () => {
        try {
            const res = await axios.post(`/api/logout`, {
                API_URL,
                MICRO_SERVICE: 'employer-security',
            });

            if (res.status === 200) {
                await router.push(
                    translatePathWithParam(
                        routes,
                        '/login',
                        router.locale,
                        siteConfig.homePagePath
                    )
                );
                setCustomTheme({});
                setUserInfos(undefined);
                queryClient.clear();
            }
        } catch (err) {
            throw new Error(err.message);
        }
    };

    return { logout };
}

export { useLogout };
