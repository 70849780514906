import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { APIError, FetchQueryParams, FormObject } from 'core';

const activationFormPath = '/api/proxy/employer-security/activation/password';

async function fetchActivationForm({ queryKey: [urn] }: FetchQueryParams) {
    try {
        const response = await axios.options(urn);

        return response.data.data as FormObject;
    } catch (error) {
        throw {
            code: error?.response?.status,
        };
    }
}

export function useActivationForm() {
    return useQuery<FormObject, APIError>({
        queryKey: [activationFormPath],
        queryFn: fetchActivationForm,
    });
}
