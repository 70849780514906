import React from 'react';
import { VisuallyHidden, Stack } from '@chakra-ui/react';
import { FormattedMessage } from 'react-intl';
import { LayoutBoundary } from 'components/Layouts';
import {
    LoginForm,
    LoginLogo,
    coreSharedMessages,
    emptyServerSideProps,
} from 'core';
import { useWindowBreakpoints } from 'design-system/hooks';
import { ThemeContext } from 'themes';
import siteConfig from 'lib/site/config.json';
import { loginFormWidgets, withAuthRedirect } from 'lib/auth';
import { FullCenteredScreenLayout } from 'core/components/Layouts/FullCenteredScreen';

const lostCredentialsLinks = [
    {
        href: '/lost-password',
        message: coreSharedMessages.lostCredentialsLink,
    },
];

export function LoginPage() {
    const { client } = siteConfig;
    const { isMobile } = useWindowBreakpoints();
    const { setVariant } = React.useContext(ThemeContext);

    React.useEffect(() => {
        setVariant(siteConfig.theme.variant);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FullCenteredScreenLayout title={coreSharedMessages?.loginTitle}>
            <VisuallyHidden>
                <h1>
                    <FormattedMessage {...coreSharedMessages.loginTitle} />
                </h1>
            </VisuallyHidden>

            <Stack p={isMobile ? 0 : 6} spacing="6">
                <LoginLogo logo={client.logo} />
                <LoginForm
                    config={siteConfig}
                    LayoutBoundary={LayoutBoundary}
                    widgets={loginFormWidgets}
                    footerLinks={lostCredentialsLinks}
                />
            </Stack>
        </FullCenteredScreenLayout>
    );
}

export default LoginPage;

export const getServerSideProps = withAuthRedirect(emptyServerSideProps);
