import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import type { FormObject, FetchQueryParams, APIError } from 'core';

const path =
    '/api/proxy/employer-security/public/user/password/reset/validation/[resetToken]';

export async function fetchResetPassword({
    queryKey: [urn, resetToken],
}: FetchQueryParams) {
    try {
        const response = await axios.options(
            urn.replace('[resetToken]', resetToken)
        );
        return response.data.data as FormObject;
    } catch (error) {
        throw {
            code: error?.response?.status,
        };
    }
}

export function useResetPassword(resetToken: string) {
    return useQuery<FormObject, APIError>({
        queryKey: [path, resetToken],
        queryFn: fetchResetPassword,
    });
}
