import { WidgetKeys } from 'core';

export const loginFormWidgets = [
    {
        id: 'username',
        label: '',
        options: [],
        defaultValue: '',
        placeholder: 'login.email.placeholder',
        readonly: false,
        type: 'text',
        title: 'login.username',
        validations: {
            allowNumbers: true,
            allowLetters: true,
            required: true,
        },
    },
    {
        id: 'password',
        label: '',
        options: [],
        defaultValue: '',
        placeholder: '',
        readonly: false,
        type: 'password',
        title: 'login.password',
        validations: {
            required: true,
        },
    },
    {
        id: 'shouldRememberUsername',
        label: '',
        options: [],
        defaultChecked: false,
        type: 'checkbox',
        description: 'login.remember-username',
        validations: {},
        size: 'sm',
    },
] as WidgetKeys[];
