import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { APIError, FetchQueryParams, useRedirect } from 'core';

const path = '/api/activation';

export async function fetchActivation({
    queryKey: [urn, token],
}: FetchQueryParams) {
    try {
        const response = await axios.post(urn, { token });
        return response.data;
    } catch (error) {
        throw {
            code: error?.response?.status,
        };
    }
}

export function useActivation(token: string) {
    const { redirect } = useRedirect();
    const res = useQuery<{ scope: 'mfa' }, APIError>({
        queryKey: [path, token],
        queryFn: fetchActivation,
    });

    if (res?.data?.scope === 'mfa') {
        redirect('/mfa');
    }

    return res;
}
