import {
    getIntlPath,
    getAuthToken,
    GetServerSidePropsContextWithAuth,
} from 'core';
import { siteConfig } from 'lib/site';
import routes from 'routes';

function withAuthRedirect(handler: any) {
    return (context: GetServerSidePropsContextWithAuth) => {
        const token = getAuthToken(context.req);

        if (token) {
            const destination = getIntlPath(
                routes,
                siteConfig.homePagePath,
                context.locale
            );

            return {
                redirect: {
                    destination,
                    permanent: false,
                },
            };
        }

        return handler(context);
    };
}

export { withAuthRedirect };
